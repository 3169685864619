import { Chip, chipClasses } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CustomChip = styled(Chip)(({ theme }) => ({
  ["&"]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.neutral.light,
    marginX: "2px",
    [`& .${chipClasses.deleteIcon}`]: {
      color: theme.palette.neutral.light,
    },
  },
}));
