import { Actions, Can, SubjectsNames } from "@/casl";

import OfferApplicationDetailsLayout from "@/pages/store/sevicesOffers-page/application-history/offer-application-details-layout";
import { useIsAuthenticated } from "@azure/msal-react";
import ErrorHandlerBox from "@components/error-handler-box";
import { ComponentType, FC, PropsWithChildren, Suspense, lazy as lazyImport } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ErrorPage from "@pages/error";

const lazy = (importFn: () => Promise<{ default: ComponentType<any> }>) => {
  // High order function to quickly handle import error with minimal code changes
  return lazyImport(() =>
    importFn()
      .then(res => res) // return component if success
      .catch(e => {
        if (e.message.includes("Failed to fetch dynamically imported module"))
          return import("@pages/error/obsolete-page-error"); // There has been a deployment and this module is no longer useful
        return e; //uncaught error
      })
  );
};

const CalendarLayout = lazy(() => import("@/pages/calendar"));
// const MyCalendarPage = lazy(() => import("@/pages/calendar/mycalendar-page"));
const MyCalendarPageV2 = lazy(
  () => import("@/pages/calendar/mycalendar-page/mycalnder-page-updated")
);
// const CalendarRessourcesPage = lazy(() => import("@/pages/calendar/rooms-page"));
// const CalendarColleagues = lazy(() => import("@/pages/calendar/colleagues-page"));

const SharedCalendarRessourcesPage = lazy(() => import("@/pages/calendar/shared-ressources-page"));
const DocumentsLayout = lazy(() => import("@/pages/documents"));
const LibraryPage = lazy(() => import("@/pages/documents/library"));
const EmployeesLayout = lazy(() => import("@/pages/employees"));
const EmployeesListPage = lazy(() => import("@/pages/employees/employees-list"));
const SharedResourcesPage = lazy(() => import("@/pages/employees/shared-resources"));
const DirectoryPro = lazy(() => import("@/pages/employees/directory-pro"));
const ResourcesDetailsPage = lazy(
  () => import("@/pages/employees/shared-resources/resources-details")
);
const FeedbackLayout = lazy(() => import("@/pages/feedbacks"));
const FeedbackReportPage = lazy(() => import("@/pages/feedbacks/assigned-to-me"));
const FeedbackWaitingPage = lazy(() => import("@/pages/feedbacks/all-feedbacks"));
const MyFeedbacksPage = lazy(() => import("@/pages/feedbacks/my-feedbacks"));
const NewsAnnouncementsPage = lazy(() => import("@/pages/news/announcements-page"));
const AnnouncementDetailsPage = lazy(() => import("@/pages/news/announcements-page/details"));
const NewsEventsPage = lazy(() => import("@/pages/news/events-page"));
const EventDetailsPage = lazy(() => import("@/pages/news/events-page/details"));
const NewsDetailsPage = lazy(() => import("@/pages/news/news-page/details"));
const NewsSettingsPage = lazy(() => import("@/pages/news/settings"));
const CategoryAnnouncementPage = lazy(() => import("@/pages/news/settings/announcement"));
const CategoryEventPage = lazy(() => import("@/pages/news/settings/events"));
const CategoryNewsPage = lazy(() => import("@/pages/news/settings/news"));
const ProfileLayout = lazy(() => import("@/pages/profile"));
const CompanyProfileLayout = lazy(() => import("@/pages/profile/company-profile"));
const CompanyBusinessInformationPage = lazy(
  () => import("@/pages/profile/company-profile/company-profile-form/companyBusinessInformation")
);
const ContactInformationPage = lazy(
  () => import("@/pages/profile/company-profile/company-profile-form/contactInformation")
);

const TaxInformationPage = lazy(
  () => import("@/pages/profile/company-profile/company-profile-form/taxInformation")
);

const DigitalInformationPage = lazy(
  () => import("@/pages/profile/company-profile/company-profile-form/digitalInformation")
);

const DocumentsCompanyPage = lazy(() => import("@/pages/profile/certificates-page"));

const ProfileEmployeeLayout = lazy(() => import("@/pages/profile/my-profile"));
const ProfileEmployeePage = lazy(
  () => import("@/pages/profile/my-profile/profile-form/personalInformation")
);

const ProfileBusinessPage = lazy(() => import("@/pages/profile/my-profile/profile-form/business"));

const ProfileAddressPage = lazy(() => import("@/pages/profile/my-profile/profile-form/address"));
const QuotationsPage = lazy(() => import("@/pages/profile/quotations"));

const DocumentsEmployee = lazy(() => import("@pages/profile/Documents/documentsEmployee"));

const PaymentPage = lazy(() => import("@/pages/profile/payment"));
const SubscribePage = lazy(() => import("@/pages/profile/subscribe"));
const Layout = lazy(() => import("@pages/layout"));
const TrackSignaturesPage = lazy(() => import("@pages/documents/track-signatures"));
const MySignaturesPage = lazy(() => import("@pages/documents/track-signatures/my-signatures"));
const SignInvitationsPage = lazy(
  () => import("@pages/documents/track-signatures/sign-invitations")
);
const SigningPage = lazy(() => import("@pages/documents/signing-page"));
const NewsLayout = lazy(() => import("@pages/news"));
const NewsPage = lazy(() => import("@pages/news/news-page"));
const StoreLayout = lazy(() => import("@pages/store"));
const StoreCatalogLayout = lazy(() => import("@pages/store/catalog-page"));
const AllProductsCatalogPage = lazy(() => import("@pages/store/catalog-page/all-products"));
const OurProductsCatalogPage = lazy(() => import("@/pages/store/catalog-page/our-products"));
const StoreServicesOffersLayout = lazy(() => import("@pages/store/sevicesOffers-page/"));
const StoreAllServicesOffersPage = lazy(() => import("@pages/store/sevicesOffers-page/all-offer"));
const StoreMyApplicationServicesOffersPage = lazy(
  () => import("@pages/store/sevicesOffers-page/my-application")
);
const StoreApplicationHistoryServicesOffersPage = lazy(
  () => import("@pages/store/sevicesOffers-page/application-history")
);
const StoreApplicationHistoryServicesOffersDetailsPage = lazy(
  () =>
    import("@pages/store/sevicesOffers-page/application-history/offer-application-details/index")
);

const OfferApplicationReceived = lazy(
  () =>
    import("@pages/store/sevicesOffers-page/application-history/offer-applications-received/index")
);

const StoreEnterpriseDetailsPage = lazy(
  () => import("@pages/store/entreprise-page/entreprise-details-page")
);
const StoreEnterpriseCatalogPage = lazy(
  () => import("@pages/store/entreprise-page/entreprise-details-page/catalog")
);
const StoreEntepriseDocsPage = lazy(
  () => import("@pages/store/entreprise-page/entreprise-details-page/documents")
);
const StoreEnterpriseProductDetailPage = lazy(
  () => import("@pages/store/entreprise-page/entreprise-details-page/catalogueDetail")
);
const StoreEnterpriseProfilePage = lazy(
  () => import("@pages/store/entreprise-page/entreprise-details-page/profile")
);
const StoreEnterpriseReviewPage = lazy(
  () => import("@pages/store/entreprise-page/entreprise-details-page/reviews")
);
const StoreEnterpriseOffersServicesPage = lazy(
  () => import("@pages/store/entreprise-page/entreprise-details-page/servicesOffers")
);
const StoreEnterpriseOffersDetailsPage = lazy(
  () =>
    import("@pages/store/entreprise-page/entreprise-details-page/servicesOffers/SingleOfferDetails")
);
const EnterpriseFeed = lazy(
  () => import("@/pages/store/entreprise-page/entreprise-details-page/feed")
);
const EnterprisePage = lazy(() => import("@pages/store/entreprise-page/index"));
const PersoLiteLayout = lazy(() => import("@pages/perso-lite/index"));
const PersoLiteDocumentsPage = lazy(() => import("@pages/perso-lite/directories-page/index"));
const PersoLiteSharedDocumentsPage = lazy(
  () => import("@pages/perso-lite/shared-documents-page/index")
);
const PersoLiteDirectoryDetailsPage = lazy(
  () => import("@pages/perso-lite/directory-page-details")
);
const PersoLiteReceivedDocumentsPage = lazy(
  () => import("@pages/perso-lite/received-documents-page")
);
const PersoLiteSharedDirectoriesPage = lazy(
  () => import("@pages/perso-lite/shared-directories-page")
);
const Playground = lazy(() => import("@/pages/playground"));
const KnowledgeBaseLayout = lazy(() => import("@/pages/knowledge-base"));
const KnowledgeBaseListPage = lazy(
  () => import("@/pages/knowledge-base/knowledge-base-list/index")
);
const KnowledgeBaseInternalPage = lazy(
  () => import("@/pages/knowledge-base/knowledge-base-internal")
);

const KnowledgeBaseCreate = lazy(() => import("@/pages/knowledge-base/knowledge-base-create"));

const KnowledgeBaseDetailPage = lazy(() => import("@/pages/knowledge-base/knowledge-base-detail"));
const EditDocumentPage = lazy(() => import("@/pages/documents/library/edit-document"));
const InvitationConfirmationPage = lazy(() => import("@/pages/common/invitation-confirmation"));

const EditSpreadsheetPage = lazy(() => import("@/pages/documents/library/edit-spreadsheet"));
const TasksManagerLayout = lazy(() => import("@pages/tasks-manager"));
const TasksManagerPage = lazy(() => import("@pages/tasks-manager/kanban"));
const FicheEntrepriseLayout = lazy(() => import("@pages/ficheEntreprise/index.tsx"));
const FicheEntreprise = lazy(() => import("@pages/ficheEntreprise/FicheEntreprise"));
const Resume = lazy(() => import("@pages/ficheEntreprise/FicheEntreprise/components/resume"));
const Documents = lazy(() => import("@pages/ficheEntreprise/FicheEntreprise/components/documents"));
const Marketing = lazy(() => import("@pages/ficheEntreprise/FicheEntreprise/components/marketing"));
const CertificationRGE = lazy(
  () => import("@pages/ficheEntreprise/FicheEntreprise/components/certificationsRGE")
);
const PrestatairesLayout = lazy(() => import("@pages/prestataires/index.tsx"));
const PdfViewerPage = lazy(() => import("@/pages/documents/pdf-viewer"));
const MyFeeds = lazy(() => import("@/pages/my-feeds/index.tsx"));
const MyCommunityFeeds = lazy(() => import("@/pages/communities"));
const MyNetworkLayout = lazy(() => import("@/pages/my-network/index"));
const NetworkNavbarLayout = lazy(() => import("@/pages/layout/network-navbar-layout"));
const Connections = lazy(() => import("@/pages/my-network/connections/connections"));
const Invitations = lazy(() => import("@/pages/my-network/invitations/invitations"));
const Suggestions = lazy(() => import("@/pages/my-network/suggestions/suggestions"));
const PartnersLayout = lazy(() => import("@/pages/my-network/partners"));
const PartnersDetails = lazy(() => import("@/pages/my-network/partners/partners"));
const PartnershipsRequestsReceived = lazy(
  () => import("@/pages/my-network/partners/partners-requests-received")
);
const PartnershipsRequestsSent = lazy(
  () => import("@/pages/my-network/partners/partners-requests-sent")
);
const SavProLayout = lazy(() => import("@/pages/sav-pro/index"));
const SavProAllTickets = lazy(() => import("@/pages/sav-pro/components/all-tickets"));
const SavProOwnTickets = lazy(() => import("@/pages/sav-pro/components/own-tickets"));
const SavProManagementLayout = lazy(() => import("@/pages/sav-pro/components/sav-pro-management"));
const SavProClients = lazy(() => import("@/pages/sav-pro/components/sav-pro-clients"));
const SavProProducts = lazy(() => import("@/pages/sav-pro/components/sav-pro-products"));
const InboxPage = lazy(() => import("@/pages/inbox"));
const LogoutSessionPage = lazy(() => import("@/components/logout-session"));
const UsersLayout = lazy(() => import("@/pages/profile/users"));
const AllUsersPage = lazy(() => import("@/pages/profile/users/all-users"));
const InvitationsUsersPage = lazy(
  () => import("@/pages/profile/users/users-invitations/index.tsx")
);

const MyMapsLayout = lazy(() => import("@/pages/maps"));
const MyMapsPage = lazy(() => import("@/pages/maps/my-maps"));
const CreateMapPage = lazy(() => import("@/pages/maps/create"));
const EditMapPage = lazy(() => import("@/pages/maps/edit"));

interface GuardProps extends PropsWithChildren {
  action: Actions;
  subject: SubjectsNames;
  navigationFallback: string;
}

const SafeGuard = ({ action, subject, children, navigationFallback }: GuardProps) => (
  <Can I={action} a={subject} passThrough>
    {allowed => (allowed ? children : <Navigate to={navigationFallback} />)}
  </Can>
);

const Root: FC = () => {
  const isAuthed = useIsAuthenticated();
  return (
    <BrowserRouter>
      <Routes>
        {isAuthed && (
          <>
            <Route
              path="/invitations/:id"
              element={
                <Suspense>
                  <InvitationConfirmationPage />
                </Suspense>
              }
            />
            <Route
              path="/"
              element={
                <Suspense>
                  <Layout />
                </Suspense>
              }
            >
              <Route index element={<Navigate to="network-ila26/my-feeds" />} />
              <Route path="network-ila26" element={<NetworkNavbarLayout />}>
                <Route index element={<Navigate to="my-feeds" />} />
                <Route path="my-feeds">
                  <Route index element={<MyFeeds />} />
                  <Route path="communities" element={<MyCommunityFeeds />} />
                </Route>
                <Route path="my-network" element={<MyNetworkLayout />}>
                  <Route index element={<Navigate to="suggestions" />} />
                  <Route path="invitations" element={<Invitations />} />
                  <Route path="connections" element={<Connections />} />
                  <Route path="suggestions" element={<Suggestions />} />

                  <Route path="partners" element={<PartnersLayout />}>
                    <Route index element={<PartnershipsRequestsReceived />} />
                    <Route index path="sent" element={<PartnershipsRequestsSent />} />
                    <Route path=":Id" element={<PartnersDetails />} />
                  </Route>
                </Route>
                <Route path="inbox" element={<InboxPage />} />
                <Route path="shared-resources" element={<Navigate to="all" />} />
                <Route path="shared-resources/all" element={<DirectoryPro />} />
                <Route path="shared-resources/:userId" element={<ResourcesDetailsPage />} />
                <Route path="store" element={<StoreLayout />}>
                  <Route index element={<Navigate to="all" />} />
                  <Route path="all" element={<EnterprisePage />} />
                  <Route path="company">
                    <Route index element={<EnterprisePage />} />
                    <Route path="details/:id" element={<StoreEnterpriseDetailsPage />}>
                      <Route index element={<StoreEnterpriseProfilePage />} />
                      <Route path="employees">
                        <Route index element={<SharedResourcesPage />} />
                        <Route path="details/:userId" element={<ResourcesDetailsPage />} />
                      </Route>
                      <Route path="catalog">
                        <Route index element={<StoreEnterpriseCatalogPage />} />
                        <Route
                          path="products/:productId"
                          element={<StoreEnterpriseProductDetailPage />}
                        />
                      </Route>
                      <Route path="reviews" element={<StoreEnterpriseReviewPage />} />
                      <Route path="news" element={<p>News Component Here</p>} />
                      <Route path="offers">
                        <Route index element={<StoreEnterpriseOffersServicesPage />} />
                        <Route path=":offerId" element={<StoreEnterpriseOffersDetailsPage />} />
                      </Route>
                      <Route path="documents" element={<StoreEntepriseDocsPage />} />
                      <Route path="feed" element={<EnterpriseFeed />} />
                    </Route>
                  </Route>
                </Route>
                <Route path="offers" element={<StoreServicesOffersLayout />}>
                  <Route index element={<StoreAllServicesOffersPage />} />
                  <Route
                    path="application-history"
                    element={<StoreApplicationHistoryServicesOffersPage />}
                  >
                    <Route path="details/:offerId" element={<OfferApplicationDetailsLayout />}>
                      <Route index element={<StoreApplicationHistoryServicesOffersDetailsPage />} />
                      <Route path="applications-received" element={<OfferApplicationReceived />} />
                    </Route>
                  </Route>
                  <Route path="my-application" element={<StoreMyApplicationServicesOffersPage />} />
                  <Route path=":offerId" element={<StoreEnterpriseOffersDetailsPage />} />
                </Route>

                <Route path="catalog" element={<StoreCatalogLayout />}>
                  <Route index element={<AllProductsCatalogPage />} />
                  <Route path="our-products" element={<OurProductsCatalogPage />} />
                  <Route
                    path="products/:productId"
                    element={<StoreEnterpriseProductDetailPage />}
                  />
                </Route>
              </Route>
              <Route path="fiche" element={<FicheEntrepriseLayout />}>
                <Route element={<FicheEntreprise />}>
                  <Route index element={<Navigate to="resume" replace />} />
                  <Route path="resume" element={<Resume />} />
                  <Route path="documents" element={<Documents />} />
                  <Route path="marketing" element={<Marketing />} />
                  <Route path="certifications" element={<CertificationRGE />} />
                </Route>
                <Route path="recherche" element={<div>recherche</div>} />
              </Route>

              <Route path="providers" element={<PrestatairesLayout />}>
                <Route index element={<div>home prestataires</div>} />
                <Route path="wallet" element={<div>wallet</div>} />
                <Route path="missions" element={<div>our missions</div>} />
              </Route>

              <Route path="news" element={<NewsLayout />}>
                <Route index element={<NewsPage />} />
                <Route path="events" element={<NewsEventsPage />} />
                <Route path="communiques" element={<NewsAnnouncementsPage />} />
                <Route
                  path="settings"
                  element={
                    <SafeGuard action="View" subject="News" navigationFallback="news">
                      <NewsSettingsPage />
                    </SafeGuard>
                  }
                >
                  <Route index element={<CategoryNewsPage />} />
                  <Route path="category-event" element={<CategoryEventPage />} />
                  <Route path="category-announcement" element={<CategoryAnnouncementPage />} />
                </Route>
                <Route path="news-details/:id" element={<NewsDetailsPage />} />
                <Route path="event-details/:id" element={<EventDetailsPage />} />
                <Route path="announcement-details/:id" element={<AnnouncementDetailsPage />} />
              </Route>

              <Route path="directory-pro" element={<EmployeesLayout />}>
                <Route index element={<EmployeesListPage />} />
                <Route path=":userId" element={<ResourcesDetailsPage />} />
              </Route>
              <Route path="knowledge-base" element={<KnowledgeBaseLayout />}>
                <Route index element={<KnowledgeBaseListPage />} />
                <Route path=":id/:internal" element={<KnowledgeBaseDetailPage />} />
                <Route path="create" element={<KnowledgeBaseCreate />} />
                <Route path="internal" element={<KnowledgeBaseInternalPage />} />
              </Route>
              <Route path="documents" element={<DocumentsLayout />}>
                <Route index element={<LibraryPage />} />
                <Route path="/documents/:id/create-signature-request" element={<SigningPage />} />
                <Route path=":id/document-editor" element={<EditDocumentPage />} />
                <Route path=":id/spreadsheet-editor" element={<EditSpreadsheetPage />} />
                <Route path=":id/pdf-viewer" element={<PdfViewerPage />} />
              </Route>
              <Route path="track-signatures" element={<TrackSignaturesPage />}>
                <Route index element={<MySignaturesPage />} />
                <Route path={"sign-invitation"} element={<SignInvitationsPage />} />
                <Route path=":id?/create-signature-request" element={<SigningPage />} />
              </Route>
              {/* TODO: add clients and products layout */}
              <Route path="sav-pro" element={<SavProLayout />}>
                <Route index element={<Navigate to="own" replace />} />
                <Route path="own" element={<SavProOwnTickets />} />
                <Route path="all" element={<SavProAllTickets />} />
                <Route path="management" element={<SavProManagementLayout />}>
                  <Route index element={<Navigate to="clients" replace />} />
                  <Route path="clients" element={<SavProClients />} />
                  <Route path="products" element={<SavProProducts />} />
                </Route>
              </Route>
              <Route path="calendar" element={<CalendarLayout />}>
                {/* <Route index element={<MyCalendarPage />} /> */}
                <Route index element={<MyCalendarPageV2 />} />
                {/* <Route path="ressources" element={<CalendarRessourcesPage />} />
                <Route path="colleagues" element={<CalendarColleagues />} /> */}
                <Route
                  path="shared-ressources"
                  element={
                    <SafeGuard action="View" subject="Calendar" navigationFallback="calendar">
                      <SharedCalendarRessourcesPage />
                    </SafeGuard>
                  }
                />
              </Route>
              <Route path="inbox" element={<InboxPage />} />
              <Route path="feedback" element={<FeedbackLayout />}>
                <Route index element={<MyFeedbacksPage />} />
                <Route path="report" element={<FeedbackReportPage />} />
                <Route
                  path="allocation"
                  element={
                    <SafeGuard action="View" subject="Feedback" navigationFallback="feedback">
                      <FeedbackWaitingPage />
                    </SafeGuard>
                  }
                />
              </Route>
              <Route path="tasks" element={<TasksManagerLayout />}>
                <Route path="/tasks/:id" element={<TasksManagerPage />} />
              </Route>
              {/*
              <Route path="tasks-manager" element={<TasksManagerLayout />} >
                  <Route path="/tasks-manager/:id" element={<TasksManagerPage />} />
              </Route> */}

              <Route path="profile" element={<ProfileLayout />}>
                <Route index element={<Navigate to="me" replace />} />
                <Route path="me" element={<ProfileEmployeeLayout />}>
                  <Route index element={<ProfileEmployeePage />} />
                  <Route path="function" element={<ProfileBusinessPage />} />
                  <Route path="address" element={<ProfileAddressPage />} />
                  <Route path="documents" element={<DocumentsEmployee />} />
                </Route>

                <Route path="company" element={<CompanyProfileLayout />}>
                  <Route index element={<CompanyBusinessInformationPage />} />
                  <Route path="contact" element={<ContactInformationPage />} />
                  <Route path="fiscal" element={<TaxInformationPage />} />
                  <Route path="digital" element={<DigitalInformationPage />} />
                  <Route path="documents" element={<DocumentsCompanyPage />} />
                  <Route path="quotations" element={<QuotationsPage />} />
                </Route>

                <Route path="subscribe" element={<SubscribePage />} />
                <Route path="users" element={<UsersLayout />}>
                  <Route index element={<AllUsersPage />} />
                  <Route path="invitations" element={<InvitationsUsersPage />} />
                </Route>
                <Route path="pay" element={<PaymentPage />} />
              </Route>
              <Route path="persoLite" element={<PersoLiteLayout />}>
                <Route index element={<PersoLiteDocumentsPage />} />
                <Route path="directory">
                  <Route index element={<PersoLiteDocumentsPage />} />
                  <Route
                    path="details/:id/:name/:shared"
                    element={<PersoLiteDirectoryDetailsPage />}
                  />
                </Route>
                <Route path="sharedDocuments" element={<PersoLiteSharedDocumentsPage />} />
                <Route path="sharedDirectories">
                  <Route index element={<PersoLiteSharedDirectoriesPage />} />
                  <Route
                    path="details/:id/:name/:user"
                    element={<PersoLiteDirectoryDetailsPage />}
                  />
                </Route>
                <Route path="receivedDocuments" element={<PersoLiteReceivedDocumentsPage />} />
              </Route>

              <Route path="maps" element={<MyMapsLayout />}>
                <Route index element={<MyMapsPage />} />
                {/* <Route path="create" element={<CreateMapPage />} />  */}
              </Route>

              <Route path="maps">
                <Route path="create" element={<CreateMapPage />} />
                <Route path=":id" element={<EditMapPage />} />
              </Route>

              {import.meta.env.DEV && <Route path="playground" element={<Playground />} />}
              <Route path="error" element={<ErrorPage />} />
            </Route>
            <Route path="logout-session" element={<LogoutSessionPage />} />
          </>
        )}
        <Route path="*" element={<ErrorHandlerBox errorType={"404"} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Root;
