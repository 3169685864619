import React, { FC, ReactElement, ReactNode, useMemo } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme, CssBaseline } from "@mui/material"; //import useMediaQuery for dynamic theme mode
import { Global, css } from "@emotion/react";
import { generateTheme } from "./theme";

//import { useSelector } from "react-redux";
//import { RootState } from "@/store";

declare module "@mui/material/styles" {
  interface Palette {
    violet: Palette["primary"];
  }

  interface PaletteOptions {
    violet?: Palette["primary"];
  }
}

const Theme: FC<Props> = props => {
  const { children } = props;
  const theme = useMemo(() => createTheme(generateTheme("light")), []);
  document.body.style.backgroundColor = theme.palette.customGrey.light;

  // Dynamic theme mode
  // Detects if the user's system is set to dark mode by default
  //const isSystemDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  //const themeState = useSelector((state: RootState) => state.theme.theme);

  //const theme = useMemo(() => {
  //  const mode = themeState === "system" ? (isSystemDarkMode ? "dark" : "light") : themeState;
  //  const theme = createTheme(generateTheme(mode));
  //  document.body.style.backgroundColor = theme.palette.customGrey.light;
    //return theme;
  //}, [themeState]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Global
        styles={css`
          body {
            margin: 0;
            padding: 0;
          }

          /* Scroll bar window */
          /* width */

          ::-webkit-scrollbar {
            width: 5px;
          }

          /* Track */

          ::-webkit-scrollbar-track {
            background: ${theme.palette.mode === "light"
                ? theme.palette.customLightGrey.light
                : theme.palette.gray.contrastText};
          }

          /* Handle */

          ::-webkit-scrollbar-thumb {
            background: ${theme.palette.mode === "light"
                ? theme.palette.secondary.main
                : theme.palette.customLightGrey.light};
            border-radius: 4px;
          }

          /* Handle on hover */

          ::-webkit-scrollbar-thumb:hover {
            background: ${theme.palette.mode === "light"
                ? theme.palette.gray.contrastText
                : theme.palette.gray.light};
          }
        `}
      />
      {children}
    </ThemeProvider>
  );
};

interface Props {
  children: React.JSX.Element | ReactElement | ReactNode;
}

export default Theme;
