import { FC, ReactElement, ReactNode } from "react";
import { IntlProvider } from "react-intl";
import { messages } from "./messages";
import { flattenObject } from "@/utils/misc";
import { useGetCurrentEmployeeQuery } from "@/store/employee/endpoints";
import { LOCALES } from "./locales";

const IntlMessages: FC<Props> = props => {
  const { children } = props;
  const { data: currentUser } = useGetCurrentEmployeeQuery();
  const locale =
    currentUser?.preferredLanguage ?? import.meta.env.VITE_APP_DEFAULT_LOCALE ?? LOCALES.FRENCH;

  return (
    <IntlProvider locale={locale} messages={flattenObject(messages[locale])}>
      {children}
    </IntlProvider>
  );
};

interface Props {
  children: React.JSX.Element | ReactElement | ReactNode;
}

export default IntlMessages;
