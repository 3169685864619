import { ApiEndpointBuilder } from "@/services/api/types";

const setEmployeePreferredLanguage = (builder: ApiEndpointBuilder) => {
  return builder.mutation<string, LanguagePreference>({
    query: args => ({
      url: `/enterpriseemployees/current/set-preferred-language`,
      method: "PUT",
      body: {
        ...args,
      },
    }),
    invalidatesTags: ["currentEmployee"],
  });
};

type LanguagePreference = {
  preferredLanguage: string;
};

export default setEmployeePreferredLanguage;
