import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { THEME_VALUES, ThemeMode } from "@/theme/palettes.ts";

const initialState: { theme: ThemeMode } = {
  theme: (localStorage.getItem("theme") as ThemeMode) ?? THEME_VALUES.SYSTEM,
};

const ThemeSlice = createSlice({
  name: "Theme",
  initialState,
  reducers: {
    changeTheme: (_, { payload }: PayloadAction<ThemeMode>) => {
      localStorage.setItem("theme", payload);
      return { theme: payload };
    },
  },
});

export const { changeTheme } = ThemeSlice.actions;
export const themeReducer = ThemeSlice.reducer;
