import { styled } from "@mui/material/styles";
import { classesIcon } from "@components/phone-country-input/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const classesTimer = {
  danger: "bg-danger",
};

export const Timer = styled("span")(({ theme }) => ({
  ["&"]: {
    color: theme.palette.neutral.main,
    fontWeight: "bold",
    marginLeft: "5px",
    [`&.${classesIcon.danger}`]: {
      color: theme.palette.error.dark,
    },
  },
}));

export const InformationIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  ["&"]: {
    fontWeight: "bold",
    color: theme.palette.error.dark,
  },
}));
