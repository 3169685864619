import { styled } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export const classesIcon = {
  danger: "bg-danger",
  success: "bg-success",
  warning: "bg-warning",
};

export const ValidationIcon = styled(FontAwesomeIcon)(({theme})=>({
  ["&"]: {
    height: "20px",
    width: "20px",
    marginRight: "8px",
    cursor: "pointer",
  },
  [`&.${classesIcon.danger}`]: {
    color: theme.palette.error.dark,
  },
  [`&.${classesIcon.success}`]: {
    color: theme.palette.green.main,
  },
  [`&.${classesIcon.warning}`]: {
    color: theme.palette.warning.light,
  },
}));

