import { styled } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, IconButton as MuiIconButton, TextField, Typography } from "@mui/material";

export const CloseIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  ["&"]: {
    fontSize: "10px",
    color: theme.palette.neutral.light,
  },
}));

export const IconButton = styled(MuiIconButton)(({ theme }) => ({
  ["&"]: {
    position: "absolute",
    right: "15px",
    top: "15px",
    background: theme.palette.neutral.dark,
    height: "15px",
    width: "15.2px",
    [`&:hover`]: {
      backgroundColor: theme.palette.customLightGrey.contrastText,
      color: theme.palette.neutral.light,
      "& *": {
        color: theme.palette.neutral.light,
      },
    },
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  ["&"]: {
    fontWeight: "bold",
    color: theme.palette.neutral.dark,
    marginBottom: "15px",
  },
}));

export const Description = styled(Typography)(({ theme }) => ({
  ["&"]: {
    color: theme.palette.neutral.dark,
  },
}));

export const Phone = styled("span")(({ theme }) => ({
  ["&"]: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
}));

export const Code = styled(Box)({
  ["&"]: {
    display: "flex",
    justifyContent: "space-between",
    margin: "20px 0px 0px 0px",
  },
});

export const CodeTextField = styled(TextField)(({ theme }) => ({
  ["&"]: {
    width: "65px",
    height: "60px",
    ".MuiInputBase-input": {
      fontSize: "24px",
      fontWeight: "bold",
      color: theme.palette.primary.main,
      textAlign: "center",
    },
  },
}));

export const ResendCodeBox = styled(Box)({
  ["&"]: {
    display: "flex",
    justifyContent: "space-between",
    margin: "15px 0px 40px 0px",
  },
});

export const ResendCode = styled("span")<{ disabled?: boolean }>(({ disabled, theme }) => ({
  ["&"]: {
    color: disabled ? "grey" : theme.palette.primary.main,
    fontWeight: "bold",
    textDecoration: "underline",
    cursor: disabled ? "default" : "pointer",
  },
}));
