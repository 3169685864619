import { api } from "@/services/api";
import employeeOtpRequest from "@/store/employee/endpoints/employee-otp-request";
import employeeValidateOtpCode from "@/store/employee/endpoints/employee-validate-otp-code";
import getEmployeeReferentById from "@/store/employee/endpoints/get-employee-refrent-by-id";
import addEmployeeProfilePicture from "./add-employee-profile-picture";
import deleteEmployeeProfilePicture from "./delete-employee-profile-picture";
import getCurrentEmployeeEndpoint from "./get-current-employee";
import getEmployeeNovuSignature from "./get-employee-novu-signature";
import updateEmployeeEndpoint from "./update-employee";
import setEmployeePreferredLanguage from "./set-preferred-language";

export const employeesApi = api.injectEndpoints({
  endpoints: build => ({
    getEmployeeReferentById: getEmployeeReferentById(build),
    updateEmployee: updateEmployeeEndpoint(build),
    getCurrentEmployee: getCurrentEmployeeEndpoint(build),
    addProfilePicture: addEmployeeProfilePicture(build),
    deleteProfilePicture: deleteEmployeeProfilePicture(build),
    employeeOtpRequest: employeeOtpRequest(build),
    employeeValidateOtpCode: employeeValidateOtpCode(build),
    getEmployeeNovuSignature: getEmployeeNovuSignature(build),
    setEmployeePreferredLanguage: setEmployeePreferredLanguage(build),
  }),
});

export const {
  useGetEmployeeReferentByIdQuery,
  useGetCurrentEmployeeQuery,
  useLazyGetCurrentEmployeeQuery,
  useUpdateEmployeeMutation,
  useAddProfilePictureMutation,
  useDeleteProfilePictureMutation,
  useEmployeeOtpRequestMutation,
  useEmployeeValidateOtpCodeMutation,
  useGetEmployeeNovuSignatureQuery,
  useSetEmployeePreferredLanguageMutation,
} = employeesApi;
