import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Locale } from './types';
import { LOCALES } from '@/i18n/locales';

// @deprecated in favor of storing the language on backend
const initialState: { locale: Locale } = {
  locale:
    (localStorage.getItem("language") as Locale) ??
    import.meta.env.VITE_APP_DEFAULT_LOCALE ??
    LOCALES.FRENCH,
};

const languageSlice = createSlice({
  name: 'Translation',
  initialState,
  reducers: {
      changeLanguage: (_, { payload }: PayloadAction<Locale>) => {
        localStorage.setItem('language', payload);
				return { locale: payload }
			}
  },
});

export const { changeLanguage } = languageSlice.actions
export const languageReducer = languageSlice.reducer
